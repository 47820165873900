<template>
  <div>
    <p class="center__msg" v-html="$t('commons.customer_center_msg')" />
    <CustomerCenterInfo :use-title="false" />
  </div>
</template>

<script>
import CustomerCenterInfo from '@/components/commons/CustomerCenterInfo.vue';
export default {
    components: {
        CustomerCenterInfo
    }
}
</script>

<style scoped lang="scss">
::v-deep {
    .customer-center-info {
        color: #000000;
        font-size: 1.8rem;
    }

    .center__msg {
        font-size: 2rem;
    }
}
</style>
